import React from "react"

const jumbotron = () => {
    return (
        <div className="jumbotron bg-center bg-cover text-white mx-auto text-center pt-32 pb-12 mt-14">
            <h2 className="text-2xl md:text-5xl leading-10 font-bold tracking-wider show-blur">
                システム開発<small className="mx-1">の</small><br className="md:hidden" />株式会社ベルクラフト
            </h2>
            <div className="mt-8 md:text-xl md:leading-10 md:tracking-wider">
                <div className="font-bold mb-4 show-blur show-blur-delayed">大阪・京都を拠点とし中小企業様向けに<br className="md:hidden" />システム開発を承っております</div>
                <div className="reveal fade-left"><span className="text-marker">DX プロジェクトの推進、お任せください</span></div>
                <div className="reveal fade-right"><span className="text-marker">Excel で日々の業務を効率化、お任せください</span></div>
                <div className="reveal fade-left"><span className="text-marker">IoT / RPA / RFID を用いた自動化、お任せください</span></div>
                <div className="reveal fade-right"><span className="text-marker">IT スキル / プログラミング講習、お任せください</span></div>
            </div>
        </div>
    )
}

export default jumbotron
