import React from "react"

const contact = () => {
    return (
        <div className="reveal fade-bottom">
            <form method="post" className="w-full mx-auto" netlify-honeypot="bot-field" data-netlify="true" name="contact" action="/thanks">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />

                <div className="flex flex-wrap w-10/12 md:w-1/2 mx-auto">
                    <div className="w-full form-group">
                        <label className="text-gray-700">メールアドレス
                            <input type="email" className="form-control mt-2 appearance-none block w-full bg-white text-gray-700 border-2 border-white rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-orange-100 focus:border-orange-300"
                                id="email" name="email" placeholder="" pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" required autocomplete="email" />
                        </label>
                    </div>

                    <div className="w-full form-group">
                        <label className="text-gray-700">お問い合わせ内容
                            <textarea className="form-control mt-2 appearance-none block w-full bg-white text-gray-700 border-2 border-white rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-orange-100 focus:border-orange-300"
                                id="contact" name="content" rows="8" required></textarea>
                        </label>
                    </div>

                    <div className="w-full form-group text-center md:text-lg mt-8">
                        <button type="submit" className="bg-orange-500 hover:bg-orange-700 text-white py-3 px-8 md:px-32 border border-orange-500 hover:border-transparent rounded inline-flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                            <span className="inline-block ml-2 font-medium">送信</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default contact
