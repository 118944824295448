import React from "react"

const about = () => {
    return (
        <table className="table-auto mt-8 mx-auto shadow-lg reveal fade-bottom">
            <tbody>
                <tr className="bg-white">
                    <td className="px-8 py-4 text-gray-600 whitespace-nowrap">会社名</td>
                    <td className="px-8 py-4">株式会社ベルクラフト</td>
                </tr>
                <tr className="bg-orange-100">
                    <td className="px-8 py-4 text-gray-600 whitespace-nowrap">設立</td>
                    <td className="px-8 py-4">2017 年</td>
                </tr>
                <tr className="bg-white">
                    <td className="px-8 py-4 text-gray-600 whitespace-nowrap">事業内容</td>
                    <td className="px-8 py-4">システム開発、技術指導、その他コンピューターに附帯する業務</td>
                </tr>
                <tr className="bg-orange-100">
                    <td className="px-8 py-4 text-gray-600 whitespace-nowrap">本社</td>
                    <td className="px-8 py-4">〒604-8151 京都府京都市中京区橋弁慶町222<br />京都いのべーしょんオフィス</td>
                </tr>
                <tr className="bg-white">
                    <td className="px-8 py-4 text-gray-600 whitespace-nowrap">代表取締役</td>
                    <td className="px-8 py-4">鈴木 進也</td>
                </tr>
            </tbody>
        </table>
    )
}

export default about
