import React from "react"
import Meta from "../components/meta"
import Navbar from "../components/navbar"
import Jumbotron from "../components/jumbotron"
import Footer from "../components/footer"
import Title from "../components/title"
import Card from "../components/card"
import About from "../components/about"
import Contact from "../components/contact"

export default function Home() {
  return (
    <>
      <Meta title="株式会社ベルクラフト" description="株式会社ベルクラフトは大阪・京都を中心に中小企業様向けに WEB システム開発を承っております。DX プロジェクトの推進や各種の自動化、お気軽にご相談ください。" />
      <div className="flex flex-col h-screen">
        <header>
          <Navbar hasContact />
          <Jumbotron />
        </header>
        <main>
          <div className="container max-w-5xl mx-auto py-4 px-4">
            <Title title="特徴" name="spec" />
            <Card heading="システム開発事例紹介" reverse={false} img={'startup-photos-800x430.jpeg'} link="/develop"
              body="業務コンサルティングを含め、お客様のニーズに合わせて課題をクリアにしベストな解決方法を提案いたします。" />
            <Card heading="IT トレーニング講習" reverse img={'pexels-photo-301926-800x430.jpeg'} link="/training"
              body="お客様のスキル・ニーズに合わせたプログラミング体験セミナーを開催します。現役エンジニアが教えます。お気軽にご相談ください。" />
            <Card heading="RPA を利用した業務自動化" reverse={false} img={'mark-516278_1920-800x430.jpeg'} link="/rpa"
              body="ホワイトカラーの業務を自動化して生産性を向上させます。DX の達成・実現に向けて一歩を踏み出しましょう。" />
            <Card heading="RFID / IoT を利用したシステム" reverse img={'adventure-2345835_1920-800x430.jpeg'} link="/rfid"
              body="私たちは数々の RFID を用いたプロジェクトを推進してきました。貴社の課題をクリアにし、解決のための最適な提案をさせていただきます。" />
            <Card heading="会社概要" reverse={false} img={'pexels-photo-533405-800x430.jpeg'} link="/about"
              body="社名の一部であるクラフトには技術や技巧という意味があります。当社は小さな会社ですが職人のようにひたむきに一歩一歩、必要とされる企業となれるよう進んでまいります。" />

            <div className="mt-8 md:mt-16"></div>

            <Title title="会社概要" name="about" />
            <About />

            <div className="mt-8 md:mt-16"></div>

            <Title title="お問い合わせ" name="contact-form" />
            <Contact />
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}
