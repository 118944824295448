import React from "react"

const card = (props) => {
    // モバイルの場合はカードの左右を入れ替えない (モバイルは表示上は縦 2 行になるため)
    return (
        <div className={`bg-white rounded-md mb-4 flex flex-wrap reveal fade-bottom ${props.reverse ? 'md:flex-row-reverse' : ''}`}>
            <div className="w-full sm:w-1/2 p-6">
                <h3 className="card-heading text-xl md:text-3xl font-bold leading-none mb-3">{props.heading}</h3>
                <p className="tracking-wide text-gray-600 mb-8">{props.body}</p>
                <div>
                    <a href={props.link} className="text-orange-500 hover:text-orange-700 inline-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                        <span className="inline-block ml-2">詳しく見る</span>
                    </a>
                </div>
            </div>
            <div className="w-full sm:w-1/2 p-6">
                <img src={props.img} alt={props.heading + 'のイメージ画像'} />
            </div>
        </div>
    )
}

export default card
